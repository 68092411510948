import React, { useEffect, useState } from 'react';
import ContentItem from './item';

import './content.scss';

function Content() {
  let [isReady, setReady] = useState(false);
  let [data, setContent] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        'json/' + process.env.REACT_APP_BRAND_NAME + '/content.json',
      );
      const data = await response.json();
      setContent(data);
      setReady(true);
    };
    fetchData();
  }, []);

  if (!isReady) {
    return '<div>..loading</div>';
  } else {
    console.log(data);
    return (
      <div className="content-page">
        <div className="cart-content">
          {data.posts.map((item, i) => {
            return <ContentItem item={item} key={i}></ContentItem>;
          })}
        </div>
      </div>
    );
  }
}
export default Content;
