import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SET_OFFERS } from './redux/reducers/offer';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
//app components
import RegistrationBlock from './registration';
import LoginModal from './loginModal';
import Offers from './offers';
import Hero from './hero';
import Content from './content';
import Cart from './cart';
import Legal from './legal';
import Spinner from './common/Spinner';
import ErrorToast from './common/ErrorToast';
import PageNotFound from './404';

//styles
import './App.css';

const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();

  const {
    config,
    config: {
      retailer: {
        dpn: { id: dpn_id },
      },
      urlParam: { ids: offerIds },
    },
  } = useSelector((state) => state);
  let mpidbyEnv = config.myEnv;
  let [isLoading, updateLoadingState] = useState(true);
  let [isFetching, updateFetchState] = useState(false);
  let [storedClips, updateStoredClips] = useState({ list: [] });

  let offers = {
    clipped: [],
    available: [],
  };

  let mediaPId = config.retailer.mpid[mpidbyEnv],
    showLogin = useSelector((state) => state.offer.show_login);

  function parseOffers(data) {
    let storage = localStorage.getItem('pbyt');
    if (storage) {
      const storedObj = JSON.parse(storage);

      updateStoredClips({ list: storedObj.myIds ? storedObj.myIds : [] });
    }
    let parsed = offers;
    if (data) {
      data.forEach((offer, idx) => {
        const display_start_date = new Date(offer.clipStartDate.iso);
        const display_end_date = new Date(offer.clipEndDate.iso);
        offer.display_start_date = display_start_date;
        offer.display_end_date = display_end_date;
        if (storedClips.list.includes(offer.mdid)) {
          Object.assign(offer, { clipped: true });
        }
        parsed.available.push(offer);
      });
    }
    dispatch(SET_OFFERS({ collections: parsed }));
    return true;
  }

  function fetchOffers() {
    //prevent refetch if already started
    updateFetchState(true);

    let url = 'offers';
    if (config.urlParam.testing) {
      url = '/json/coupons.json';
    }

    let urlObject = {};
    if (offerIds) {
      urlObject.ids = offerIds;
    } else if (mediaPId) {
      urlObject.mediaPropertyId = mediaPId;
    }
    url += '?' + queryString.stringify(urlObject);
    fetch(config.baseUrl + url, {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': dpn_id,
        Origin: window.location.origin,
        'If-Modified-Since': new Date(),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          parseOffers(data);
        }
      })
      .then(() => {
        updateLoadingState(false);
      });
  }
  function ModalBackdrop() {
    if (showLogin) {
      return <div className="modal-backdrop fade show"></div>;
    } else {
      return '';
    }
  }

  const appClasses = `App ${showLogin ? 'modal-open' : ''}`;

  if (!isFetching) {
    fetchOffers();
  }
  if (isLoading) {
    return <Spinner />;
  } else {
    const {
      REACT_APP_COLOR_BACKGROUND: background,
      REACT_APP_HAS_CONTENT: hasContent,
      REACT_APP_HAS_CART_ITEMS: hasCart,
      REACT_APP_HAS_LEGAL: hasLegal,
      REACT_APP_HAS_SECONDARY_PAGE: secondaryPage,
      REACT_APP_SECONDARY_PATH: secondaryPathTitle,
      REACT_APP_SECONDARY_LINK: secondaryLink,
      REACT_APP_SECONDARY_IMG: secondaryImg,
      REACT_APP_SECONDARY_BG: secondaryBg,
    } = process.env;
    const showContent = hasContent === 'true';
    const showCart = hasCart === 'true';
    const showLegal = hasLegal === 'true';
    const secondaryPath = secondaryPathTitle ? secondaryPathTitle.toLowerCase() : '/';
    const pathName = window.location.pathname.slice(1).toLowerCase();

    return (
      <div
        className={('App ', appClasses)}
        id="App"
        style={{
          backgroundColor:
            pathName === secondaryPath ? secondaryBg : background,
        }}
      >
        <LoginModal />
        <BrowserRouter history={history}>
          <div className="App-content container" id="App-content">
            <Switch>
              <Route path={['/', '/home']} exact>
                <Hero />
                <RegistrationBlock offers={offers} />
                <Offers offers={offers} />
                {showContent && <Content offers={offers} />}
                {showCart && <Cart offers={offers} />}
                {showLegal && <Legal />}
              </Route>
              {secondaryPage && (
                <Route path={`/${secondaryPath}`}>
                  <a
                    href={secondaryLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={secondaryImg}
                      alt={secondaryPath}
                      style={{
                        maxWidth: '960px',
                        width: '100%',
                        padding: '15px',
                      }}
                    />
                  </a>
                </Route>
              )}
              <Route component={PageNotFound} />
            </Switch>
            <ErrorToast />
          </div>
          <br />
          <br />
          <br />
        </BrowserRouter>
        <ModalBackdrop></ModalBackdrop>
      </div>
    );
  }
}
export default App;
