import React from 'react';

import './content.scss';

function ContentItem(props) {
  const { title, url, image, buttonBG, buttonColor } = props.item;
  const img_path = `/images/${process.env.REACT_APP_BRAND_NAME}/${image}`;

  return (
    <div className="cart-card">
      <img src={img_path} className="card-img-top content-img" alt="" />
      <div className="card-title h6">{title}</div>
      <button className="cart-btn" style={{ backgroundColor: buttonBG, color: buttonColor }}>
        <a href={url} target="_blank" rel="noopener noreferrer">Add to Cart</a>
      </button>
    </div>
  );
}
export default ContentItem;
